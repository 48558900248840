import { defineStore } from 'pinia'

export const ClassesStore = defineStore('classes', {
    state: () => ({ 
        count: 0, 
        name: 'Eduardo',
        mostrarFootButtons:true,
    }),
    getters: {
      doubleCount: (state) => state.count * 2,
    },
    actions: {
      increment() {
        this.count++
      },
      cambiamostrarFootButtons(valor){
        //console.log("aca se cambia mostrafootbuttons",valor)
        this.mostrarFootButtons=valor
      }
    },
  })
